import * as React from "react";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { UitkImage, UitkFigure, UitkFigureAspectRatioType } from "@egds/react-core/images";
import { UitkEmptyState } from "@egds/react-core/empty-state";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkSpacing } from "@egds/react-core/spacing";

export const DealsErrorFallback = () => {
  const { formatText } = useLocalization();
  const fallbackSrc =
    "https://a.travel-assets.com/travel-assets-manager/d4c0c838-61ca-41ee-bb38-3c3a550cbbf7/nodeals_160x160-2x.png";

  return (
    <UitkLayoutFlex direction="column">
      <UitkLayoutFlexItem>
        <UitkSpacing margin={{ block: "three" }}>
          <div data-testid="deals-error-fallback" className="Deals">
            <div className="deals-error-fallback-image">
              <UitkFigure ratio={UitkFigureAspectRatioType.R4_1} className="fallback-image" imageFit="cover">
                <UitkImage className="deals-fallback-image" alt="" src={fallbackSrc} placeholderImage={false} />
              </UitkFigure>
            </div>

            <div>
              <UitkEmptyState>
                <UitkHeading tag="h2" size={5}>
                  {formatText("deals.fallbackView.title")}
                </UitkHeading>
                <UitkText>{formatText("deals.fallbackView.cta")}</UitkText>
              </UitkEmptyState>
            </div>
          </div>
        </UitkSpacing>
      </UitkLayoutFlexItem>
    </UitkLayoutFlex>
  );
};
