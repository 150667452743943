import * as React from "react";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkLoader } from "@egds/react-core/loader";

export const DealsLoader = (props: any) => (
  <UitkLayoutFlex justifyContent="center">
    <UitkSpacing margin={{ blockstart: "four" }}>
      <UitkLayoutFlexItem>
        <div data-testid="deals-loader">
          <UitkLoader />
        </div>
      </UitkLayoutFlexItem>
    </UitkSpacing>
  </UitkLayoutFlex>
);

export default DealsLoader;
