// External libraries
import * as React from "react";
import { useLocation } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { AuthenticationState } from "bernie-context";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { useLocalization } from "@shared-ui/localization-context";
import { useBexApi } from "@shared-ui/bex-api-context";

// Uitk components
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkHeading, UitkHeadingProps } from "@egds/react-core/text";
import { UitkSecondaryButton } from "@egds/react-core/button";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";

// Components
import { DealsCarousel } from "./views/DealsCarousel";
import { DealsCarouselVR } from "./views/DealsCarouselVR";
import { DealsGrid } from "./views/DealsGrid";
import { DealsLoader } from "./components/DealsLoader";
import { DealsPlaceholder } from "./components/DealsPlaceHolder";
import { GridPlaceholder } from "./components/GridPlaceHolder";
import { DealsErrorFallback } from "./components/DealsErrorFallback";
import { DealsDebugInfo } from "./components/DealsDebugInfo";
import { MerchCampaignCard } from "components/shared/Merchandising/Common/MerchCampaignCard/MerchCampaignCard";
import { CampaignHeader } from "components/shared/Merchandising/Common/CampaignHeader/CampaignHeader";
import { locKeys } from "components/flexComponents/MerchCampaign/l10n";
import { withStores } from "stores";

// Typings
import {
  DealsProps,
  DealsFlexModuleResult,
  DealsComponentProps,
  DealsQueryComponentProps,
  DealsViewsProps,
  CampaignDetail,
  OfferItem,
  DealsViews,
} from "./typings";
import { CampaignByIdQueryQueryVariables, CampaignByIdQueryQuery } from "__generated__/typedefs";

// Utils
import { buildFetchMoreDeals } from "./utils/deals-utils";
import {
  Action,
  FlexTrackingInfo,
  sendDelayedClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";

// Hooks
import { useCampaignByIdQuery } from "./hooks/useCampaignByIdQuery";
import { getFmId } from "src/stores/ExperienceTemplateStore";

const dealsViews: DealsViews = {
  carousel: DealsCarousel,
  "carousel-vr": DealsCarouselVR,
  grid: DealsGrid,
};

const hiddenHeadingResultsConfig: UitkHeadingProps = { tag: "h2", size: 8 };

const Deals = withStores(
  "flexViewModel",
  "flexModuleModelStore"
)(
  observer((props: DealsProps) => {
    const { templateComponent, flexViewModel, context, flexModuleModelStore } = props;

    if (!templateComponent) {
      return null;
    }

    const {
      metadata: { id },
    } = templateComponent;
    const model = flexModuleModelStore.getModel(id) as DealsFlexModuleResult | null;

    if (!model) {
      return null;
    }

    const { campaignId, enableTripAttach } = model;

    const shouldRender = () => {
      return (
        campaignId &&
        (!enableTripAttach || (enableTripAttach && context.user.authState === AuthenticationState.AUTHENTICATED))
      );
    };

    if (!shouldRender()) {
      return null;
    }

    return <DealsQueryComponent model={model} templateComponent={templateComponent} flexViewModel={flexViewModel} />;
  })
);

const DealsQueryComponent: React.FC<DealsQueryComponentProps> = (props) => {
  const { model, templateComponent, flexViewModel } = props;
  const {
    metadata: { id },
  } = templateComponent;
  const { userLocation } = flexViewModel;

  const location = useLocation();
  const { context } = useBexApi();

  const queryParams = new URLSearchParams(location.search);

  const isGridView = model.view === "grid";
  const pageSize = model.campaignContentPagination ? model.campaignContentPageSize : null || undefined;
  const userLat = queryParams.get("lat") ?? userLocation?.latitude;
  const userLong = queryParams.get("long") ?? userLocation?.longitude;

  const campaignByIdVariables = {
    context,
    campaignId: model.campaignId,
    enableTripAttach: model.enableTripAttach,
    pageNumber: 1,
    pageSize,
    userLocation: {
      userSelectedOrigin: queryParams.get("origin") || undefined,
      userLatitude: userLat,
      userLongitude: userLong,
    },
    displayPropertyCount: model.displayPropertyCount,
    referrer: queryParams.get("referrer") || undefined,
    enableLivePricing: model.enableLivePricing,
    destinationId: model.destinationId,
  };

  const { data, error, loading, fetchMore } = useCampaignByIdQuery(campaignByIdVariables);

  if (loading) {
    return isGridView ? <GridPlaceholder templateComponentId={id} /> : <DealsPlaceholder templateComponentId={id} />;
  }

  /* Overide paginationNumber field without lose all the others values */
  const paginationFetch = buildFetchMoreDeals<CampaignByIdQueryQueryVariables, CampaignByIdQueryQuery>(
    fetchMore,
    campaignByIdVariables
  );

  return (
    <DealsComponent
      data={data}
      fetchMore={paginationFetch}
      model={model}
      templateComponent={templateComponent}
      flexViewModel={flexViewModel}
      errorMessage={error?.message}
    />
  );
};

const DealsComponent: React.FC<DealsComponentProps> = inject("context")(
  observer((props) => {
    const { data, model, templateComponent, flexViewModel, fetchMore, context, errorMessage } = props;
    const { view } = model;
    const {
      metadata: { id },
    } = templateComponent;
    const fmId = getFmId(templateComponent);

    const { formatText } = useLocalization();
    const location = useLocation();
    const track = useClickTracker();
    const focusElement = React.useRef<any>();
    const showMoreButtonRef: React.RefObject<HTMLButtonElement> = React.createRef();
    const [pageNumber, setPageNumber] = React.useState<number>(1);
    const [isLoadingNextPage, setIsLoadingNextPage] = React.useState<boolean>(false);

    const queryParams = new URLSearchParams(location.search);

    const showDebugView = queryParams.get("debug") === "true";
    const isGridView = view === "grid";
    const campaign = data?.campaignRecommendations?.campaigns[0];
    const paginationReferrer = data?.campaignRecommendations?.paginationInfo?.referrer;

    React.useEffect(() => {
      showMoreButtonRef.current?.blur();
    }, []);

    React.useEffect(() => {
      setIsLoadingNextPage(false);
    }, [data]);

    const hasEnoughDeals = () => {
      return campaign?.offers?.length && campaign?.offers?.length > 0;
    };

    const showErrorFallBack = () => {
      return model.isFeatured && !hasEnoughDeals();
    };

    const loadMoreDeals = (): void => {
      showMoreButtonRef.current?.blur();
      const nextPage = pageNumber + 1;

      setPageNumber(nextPage);
      setIsLoadingNextPage(true);

      fetchMore({
        variables: {
          pageNumber: nextPage,
          referrer: paginationReferrer ?? queryParams.get("referrer"),
        },
      });
    };

    const showMoreTrack = (moduleName: string) => {
      const flexTracking: FlexTrackingInfo = {
        moduleName,
        rfrr: `${flexViewModel.trackingContext?.pageName}.offers.pagination`,
        action: Action.CLICK,
      };
      sendDelayedClickstreamTrackEvent(flexTracking, track);
    };

    const renderMerchCampaignCard = (c: CampaignDetail, o: OfferItem, index: number) => {
      return (
        <MerchCampaignCard
          offer={o}
          cardIndex={index}
          campaignId={c.campaignId}
          productTypes={c.productTypes}
          recommendedType={c.recommendedType}
          destinationView={model.destinationView}
          displayDate={model.displayDate}
          displayDescription={model.displayDescription}
          displayPrice={model.displayPrice}
          displayPropertyCount={model.displayPropertyCount}
          border
          moduleName="Deals"
        />
      );
    };

    const DealsView: React.FC<DealsViewsProps> = view ? dealsViews[view as keyof DealsViews] : DealsCarousel;

    const hasShowMoreBtn =
      model.campaignContentPagination && isGridView && campaign?.campaignContentPaginationInfo?.hasMoreContentPages;

    return (
      <>
        {showDebugView && <DealsDebugInfo errorMessage={errorMessage} data={data} campaignId={campaign?.campaignId} />}
        {showErrorFallBack() && <DealsErrorFallback />}
        {campaign && hasEnoughDeals() && (
          <UitkSpacing margin={{ block: "six" }}>
            <div data-testid="deals" className="Deals" id={id} data-fm={fmId}>
              <div data-testid="deals-carousel">
                <CampaignHeader
                  context={context}
                  campaign={campaign}
                  campaignRefs={1}
                  campaignTitle={model.title}
                  campaignCopyAlignment={model.campaignCopyAlignment}
                  showCampaignDescription={model.showCampaignDescription}
                  showCampaignTitle={model.showCampaignTitle}
                  showCampaignTermsAndConditions={model.showTermsAndConditions}
                  view={view}
                  imgSrc={model.heroImageSrc}
                  seeMoreLinkUrl={model.seeMoreLinkUrl}
                  campaignTitleSize={model.campaignTitleSize}
                />
                <UitkHeading {...hiddenHeadingResultsConfig} className="is-visually-hidden">
                  {formatText(locKeys.offers)}
                </UitkHeading>
                <DealsView
                  campaign={campaign}
                  renderMerchCampaignCard={renderMerchCampaignCard}
                  focusElement={focusElement}
                />
              </div>
              {hasShowMoreBtn && (
                <UitkSpacing padding={{ blockstart: "six", blockend: "six" }}>
                  <UitkLayoutFlex justifyContent="center">
                    <UitkLayoutFlexItem>
                      <UitkSecondaryButton
                        name="showMoreButton"
                        type="button"
                        onClick={loadMoreDeals}
                        domRef={showMoreButtonRef}
                        analytics={{
                          id: "merch.campaign",
                          callback: showMoreTrack,
                        }}
                      >
                        {formatText(locKeys.showMore)}
                      </UitkSecondaryButton>
                    </UitkLayoutFlexItem>
                  </UitkLayoutFlex>
                </UitkSpacing>
              )}
              {isLoadingNextPage && <DealsLoader />}
            </div>
          </UitkSpacing>
        )}
      </>
    );
  })
);

export default Deals;
