import * as React from "react";
import { UitkCardPlaceholder } from "@egds/react-core/cards";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { GridPlaceholderProps } from "../typings";
import { UitkLayoutGrid } from "@egds/react-core/layout-grid";
import { UitkSpacing } from "@egds/react-core/spacing";

export const GridPlaceholder = (gridPlaceholderProps: GridPlaceholderProps) => {
  const keyHelper = new ItemKeyHelper(`${gridPlaceholderProps.templateComponentId}-placeholder-Grid`);

  return (
    <UitkSpacing margin={{ blockend: "three", blockstart: "four" }}>
      <div className="Deals">
        <UitkSpacing margin={{ inlinestart: "three" }}>
          <UitkCardPlaceholder className="placeholder-header" />
        </UitkSpacing>
        <UitkSpacing margin={{ inlinestart: "three", blockstart: "two" }}>
          <UitkCardPlaceholder className="placeholder-subheader" />
        </UitkSpacing>
        <UitkSpacing margin={{ inline: "three", blockstart: "three" }}>
          <UitkLayoutGrid columns={{ medium: 2, extra_large: 3, small: 1 }} space="three">
            {[...Array(12)].map((val, index) => (
              <div key={keyHelper.next()}>
                <UitkCardPlaceholder className="placeholder-card" />
              </div>
            ))}
          </UitkLayoutGrid>
        </UitkSpacing>
      </div>
    </UitkSpacing>
  );
};
