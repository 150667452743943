import * as React from "react";
import { UitkCardPlaceholder } from "@egds/react-core/cards";
import { IsomorphicCarousel } from "components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { useLocalization } from "@shared-ui/localization-context";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { DealsPlaceholderProps } from "../typings";
import { UitkSpacing } from "@egds/react-core/spacing";

// placeholder for the campaign deals that will be displayed until the data is fetched
export const DealsPlaceholder = (dealsPlaceholderProps: DealsPlaceholderProps) => {
  const { formatText } = useLocalization();
  const keyHelper = new ItemKeyHelper(`${dealsPlaceholderProps.templateComponentId}-placeholder-Carousel`);

  return (
    <UitkSpacing margin={{ blockend: "three", blockstart: "four" }}>
      <div data-testid="deals-placeholder" className="Deals" id={dealsPlaceholderProps.templateComponentId}>
        <UitkSpacing margin={{ inlinestart: "three" }}>
          <UitkCardPlaceholder className="placeholder-header" />
        </UitkSpacing>
        <UitkSpacing margin={{ inlinestart: "three", blockstart: "two" }}>
          <UitkCardPlaceholder className="placeholder-subheader" />
        </UitkSpacing>
        <UitkSpacing margin={{ blockstart: "three" }}>
          <IsomorphicCarousel
            className="carouselComponent"
            itemsVisible={{
              lg: 3,
              md: 2,
              sm: 1,
            }}
            buttonText={{
              nextButton: formatText("carousel.item.next"),
              prevButton: formatText("carousel.item.prev"),
            }}
            pageBy={1}
            peek
          >
            {[...Array(4)].map((val, index) => (
              <div key={keyHelper.next()}>
                <UitkCardPlaceholder className="placeholder-card" />
              </div>
            ))}
          </IsomorphicCarousel>
        </UitkSpacing>
      </div>
    </UitkSpacing>
  );
};
