import * as React from "react";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutGrid } from "@egds/react-core/layout-grid";
import { DealsViewsProps } from "../typings";

export const DealsGrid = (props: DealsViewsProps) => {
  const { campaign, renderMerchCampaignCard, focusElement } = props;

  return (
    <UitkSpacing margin={{ blockstart: "three", inlinestart: "three", inlineend: "three" }}>
      <UitkLayoutGrid columns={{ medium: 2, extra_large: 3, small: 1 }} space="three">
        {campaign.offers!.map((offer, cardIndex) => {
          return (
            <div
              ref={focusElement}
              tabIndex={-1}
              key={`deals-grid-${offer.name}-${cardIndex}`}
              data-testid="deals-grid"
            >
              {renderMerchCampaignCard(campaign, offer, cardIndex)}
            </div>
          );
        })}
      </UitkLayoutGrid>
    </UitkSpacing>
  );
};

export default DealsGrid;
