import { useQuery } from "@apollo/client";
import { CampaignByIdQueryDocument, CampaignByIdQueryQueryVariables } from "__generated__/typedefs";

export function useCampaignByIdQuery(variables: CampaignByIdQueryQueryVariables) {
  const response = useQuery(CampaignByIdQueryDocument, {
    variables,
    ssr: false,
  });

  return { ...response, fetchMore: buildFetchMore(response.fetchMore, variables) };
}

export const buildFetchMore = (fetchMore: any, currentVariables: any) => ({ variables: newVariables }: any): void => {
  /* istanbul ignore next */
  fetchMore({
    updateQuery: (prev: any, { fetchMoreResult }: any) => {
      const prevCampaigns = prev.campaignRecommendations?.campaigns[0];
      const prevOffers = prevCampaigns?.offers;
      const moreCampaigns = fetchMoreResult?.campaignRecommendations?.campaigns[0];
      const moreOffers = moreCampaigns?.offers;

      if (!fetchMoreResult || !prevOffers || !moreOffers) {
        return prev;
      }

      return {
        ...prev,
        campaignRecommendations: {
          ...prev.campaignRecommendations,
          campaigns: [
            {
              ...prevCampaigns,
              ...moreCampaigns,
              offers: [...prevOffers, ...moreOffers],
            },
          ],
        },
      };
    },
    variables: {
      ...currentVariables,
      ...newVariables,
    },
  });
};

export default useCampaignByIdQuery;
