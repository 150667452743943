type FetchOptions<CampaignByIdQueryQueryVariables, CampaignByIdResponse> = {
  variables: CampaignByIdQueryQueryVariables;
  updateQuery: (
    previousQueryResult: CampaignByIdResponse,
    options: {
      fetchMoreResult?: CampaignByIdResponse;
      variables?: CampaignByIdQueryQueryVariables;
    }
  ) => CampaignByIdResponse;
};

type FechMore<V, D> = (options: FetchOptions<V, D>) => void;

export const buildFetchMoreDeals = <Variables, Data>(
  fetchMore: FechMore<Variables, Data>,
  currentVariables: Variables
): FechMore<Variables, Data> => ({ updateQuery, variables: newVariables }): void => {
  fetchMore({
    updateQuery,
    variables: {
      ...currentVariables,
      ...newVariables,
    },
  });
};
