import * as React from "react";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutGrid } from "@egds/react-core/layout-grid";
import { IsomorphicCarousel } from "components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { DealsViewsProps } from "../typings";

export const DealsCarouselVR = (props: DealsViewsProps) => {
  const { campaign, renderMerchCampaignCard, focusElement } = props;

  const { formatText } = useLocalization();

  return (
    <Viewport>
      <ViewSmall>
        <UitkSpacing margin={{ blockstart: "three" }}>
          <IsomorphicCarousel
            itemsVisible={{
              lg: 2,
              md: 2,
              sm: 2,
            }}
            buttonText={{
              nextButton: formatText("carousel.item.next"),
              prevButton: formatText("carousel.item.prev"),
            }}
            pageBy={1}
            peek
          >
            {campaign.offers!.slice(0, 4).map((offer, cardIndex) => {
              return (
                <div ref={focusElement} tabIndex={-1} key={`deals-carousel-vr-${offer.name}-${cardIndex}`}>
                  {renderMerchCampaignCard(campaign, offer, cardIndex)}
                </div>
              );
            })}
          </IsomorphicCarousel>
        </UitkSpacing>
      </ViewSmall>
      <ViewMedium>
        <UitkSpacing padding={{ inline: "twelve" }}>
          <UitkLayoutGrid columns={4} space="three">
            {campaign.offers!.slice(0, 4).map((offer, cardIndex) => {
              return (
                <div ref={focusElement} tabIndex={-1} key={`deals-carousel-vr-${offer.name}-${cardIndex}`}>
                  {renderMerchCampaignCard(campaign, offer, cardIndex)}
                </div>
              );
            })}
          </UitkLayoutGrid>
        </UitkSpacing>
      </ViewMedium>
    </Viewport>
  );
};

export default DealsCarouselVR;
